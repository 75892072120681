.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.home-bg{
  position: relative;
}


.contain-section{
position: absolute;
top: 150px;
width: 100%;
}
.Typewriter {
    color: #fff;
    font-size: 20px;
    line-height: 3rem;
    font-family: 'Jost', sans-serif;
   
}
.main-contain h1{
  line-height: 3.5rem;
  color: #fff;
  font-size: 45px;
  font-weight: 900;
  font-family: 'Jost', sans-serif;
  letter-spacing: -1px;
  word-spacing: -1px;
}
.main-contain p{
  font-family: 'Jost', sans-serif;
    color: #fff;
    font-size: 17px;
    line-height: 1.4;
    letter-spacing: 0.4px;
}

.main-button button {
  padding: 3px 50px;
  margin-left: -40px;
  margin-top: 15px;
  height: 55px;
  background: linear-gradient(to right, rgb(8 58 183), rgb(48 137 214));
  color: #fff;
  font-size: 20px;
  font-family: 'Jost';
  border: navajowhite;
  margin-left: 2px;
  font-weight: 600;
  width: 250px;
  transition: all .4s ease-in-out;
}
.main-button button:hover{

  transition: all .4s ease-in-out;
  background:linear-gradient(to right, rgb(0, 136, 254), rgb(8 58 183)) ;
}
.main-button button a{
  color: #fff;
}

/* .main-button lottie-player{
filter: sepia(95%) hue-rotate(166deg) saturate(500%);
} */
@media (max-width: 600px) {
  .main-contain h1 {
    font-family: Jost,sans-serif;
    font-size: 32px;    
    line-height: 2.5rem;
    text-align: left;
    padding:0px 10px;
}
.Typewriter {
  font-size: 17px;
  margin-left: 10px;
}
.main-contain p {
  font-size: 15px;
  padding: 0px 10px;
}
.main-button button {
  padding: 3px 30px;
  height: 40px;
  color: #fff;
  font-size: 16px;
  margin-left: 10px;
}
}
.singapore_modal .modal-header .btn-close {
  border: 2px solid #ccc;
  border-radius: 20px;
  font-size: 12px;
  height: 11px;
  opacity: 1;
  position: absolute;
  right: 16px;
  top: -31px;
  width: 11px;
  z-index: 1;
}
.modal .modal-header .btn-close {
  background: transparent  url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3E%3C/svg%3E") center/1em auto no-repeat;
  border: 1px solid #fff;
  border-radius: 50%;
  box-sizing: initial;
  height: 2em;
  opacity: 1;
  padding: .25em;
  box-shadow: inset 0 0 10px #5b6fe1;
  width: 2em;
  filter: brightness(0) invert(1);
  /* background: url(../img/cancel.svg) center/1em auto no-repeat; */
}
.singaporeContent {
  margin-top: -50px;
}
.singapore-box img {
  border-radius: 9px;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal .modal-header {
  color: #000000;
  border: 0;
  padding: 0rem 1.2rem;
  border-radius: 10px 10px 0 0;
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent !important;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}